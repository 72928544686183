#mapGenerator{
    position: fixed;
    padding: 20px;
    margin: 20px;
    top: 0px;
    left: 0px;
    width:100%;
    height: 100%;
    background-color: rgb(26, 26, 26);
    z-index: 11;
}



.paramsContainer {
    margin: 20px;
    padding: 20px;
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: 15px;
}

#mapGenerator label {
    justify-self: end;
}

#mapGenerator input {
    width: 50px;
}
