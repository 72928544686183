#mapGenerator {
  width: 100%;
  height: 100%;
  z-index: 11;
  background-color: #1a1a1a;
  margin: 20px;
  padding: 20px;
  position: fixed;
  top: 0;
  left: 0;
}

.paramsContainer {
  grid-gap: 15px;
  grid-template-columns: max-content 1fr;
  margin: 20px;
  padding: 20px;
  display: grid;
}

#mapGenerator label {
  justify-self: end;
}

#mapGenerator input {
  width: 50px;
}

/*# sourceMappingURL=index.6115ea9f.css.map */
